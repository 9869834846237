import React from 'react';
import Loadable from "@loadable/component";
import IntroLetter from './intro_letter/intro_letter';
import Benefits from './benefits/benefits';
import Works from './works/works';
import Reviews from './reviews/reviews';
import Faqs from './faqs/faqs';
import About from './about/about';
import global from '../styles/global.module.css';
import RegFormSSG from './registration/RegFormSSG';

//this prevents any import used on the hero to load client side due to ssr errors
const Hero = Loadable(() => import('./hero/hero'))

const TemplateC = (props) => {
    const locale = props.form.contentLocale;
    const isNotEnglish = locale === 'de' || locale === 'de-DE' || locale === 'nl' || locale === 'fr-FR' || locale === 'es-ES' || locale === 'it-IT' || locale === 'ru-RU' || locale === 'tr-TR';
    const isChinese = props.content.contentLocale === 'zh-CN' || props.content.contentLocale === 'zh-TW';

    let contentProps = {
        content: props.content,
        jumboContent: props.jumboContent,
        buyer: props.buyer,
        localeContent: props.localeContent,
        form: props.form,
        reviews: props.reviews,
        domain: props.location,
        thirdPartyContent: props.thirdParty,
        division: props.division, 
        propLocation: props.propLocation,
        formType: props.formType,
    }

	return (
        <div className={global.c2foApp}>
            <RegFormSSG />
            <Hero {...contentProps} />
            <IntroLetter content={contentProps} /> 
            <Benefits content={contentProps} />
            <Works content={contentProps} />
            { !isNotEnglish && !isChinese ?
            <Reviews content={contentProps} />
            : ''}
            <Faqs content={contentProps} />
            <About content={contentProps} />
        </div>
	);
  };

  export default TemplateC;
