export const REVIEWS_CONTENT = [
    {
        locale: 'en-US', 
        firstReviewTitle: 'C2FO is the way to go!',
        firstReviewContent: "We've had a positive experience since day one using C2FO. Our representative has been helpful and has always responded to any questions about the program immediately....",
        firstReviewName: 'Jennifer',
        secondReviewTitle: 'Our company has been working with C2FO…',
        secondReviewContent: 'Our company has been working with C2FO for about three years. C2FO representatives are extremely knowledgeable, easy to work with, and have aided in keeping our company on track...',
        secondReviewName: 'Alice',
        thirdReviewTitle: 'Exceeded My Expectations.',
        thirdReviewContent: 'C2FO customer service was above average from the very beginning of the relationship. I was able to setup a call and speak to an actual person within 30 minutes of creating my account...',
        thirdReviewName: 'T. Watt',
    },
    {
        locale: 'en-GB', 
        firstReviewTitle: 'C2FO is the way to go!',
        firstReviewContent: "We've had a positive experience since day one using C2FO. Our representative has been helpful and has always responded to any questions about the programme immediately....",
        firstReviewName: 'Jennifer',
        secondReviewTitle: 'Our company has been working with C2FO…',
        secondReviewContent: 'Our company has been working with C2FO for about three years. C2FO representatives are extremely knowledgeable, easy to work with, and have aided in keeping our company on track...',
        secondReviewName: 'Alice',
        thirdReviewTitle: 'Exceeded My Expectations.',
        thirdReviewContent: 'C2FO customer service was above average from the very beginning of the relationship. I was able to setup a call and speak to an actual person within 30 minutes of creating my account...',
        thirdReviewName: 'T. Watt',
    },
    {
        locale: 'en-IN', 
        firstReviewTitle: 'I am totally satisfied with C2FO. The C2FO team is quick to respond and very cooperative.',
        firstReviewContent: '',
        firstReviewName: '— Vyahruti Trans Enterprises',
        secondReviewTitle: "We are really very happy with the C2FO because it's easy to improve cash flow.",
        secondReviewContent: '',
        secondReviewName: '— Miloni International',
        thirdReviewTitle: 'C2FO is very useful in managing our cash flows.',
        thirdReviewContent: '',
        thirdReviewName: '— B.D. Dhalla Transport',
    },
    {
        locale: 'en-AU', 
        firstReviewTitle: 'C2FO is the way to go!',
        firstReviewContent: "We've had a positive experience since day one using C2FO. Our representative has been helpful and has always responded to any questions about the programme immediately....",
        firstReviewName: 'Jennifer',
        secondReviewTitle: 'Our company has been working with C2FO…',
        secondReviewContent: 'Our company has been working with C2FO for about three years. C2FO representatives are extremely knowledgeable, easy to work with, and have aided in keeping our company on track...',
        secondReviewName: 'Alice',
        thirdReviewTitle: 'Exceeded My Expectations.',
        thirdReviewContent: 'C2FO customer service was above average from the very beginning of the relationship. I was able to setup a call and speak to an actual person within 30 minutes of creating my account...',
        thirdReviewName: 'T. Watt',
    },
];