import React from 'react';
import Accordion from './accordion';
import vendorDataBind from '../../utils/vendorDataBind.js';
import oldGlobalStyles from '../../styles/global.module.css';
import newGlobalStyles from '../../styles/global-rebrand.module.css';
import oldFaqStyles from './faqs.module.css';
import newFaqStyles from './faqs-rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';

const Faqs = (props) => {
  const rebranded = isRebranded();
  const global = rebranded ? newGlobalStyles : oldGlobalStyles;
  const styles = rebranded ? newFaqStyles : oldFaqStyles;

  const buyer = props.content.buyer.name;
  const content = props.content.content;
  const thirdPartyContent = props.content.thirdPartyContent;
  const isTradChinese = content.contentLocale === 'zh-TW';
  const isSimpleChinese = content.contentLocale === 'zh-CN';
  const isEnglish = content.contentLocale.substr(0,2) === 'en';

  var zkh360 = props.content.buyer.permalink === 'zkh360';
  var answerOneContent;
  var answerTwoContent;
  var answerThreeContent;
  var questionFourTitle;
  var questionFourContent;

  var answerOneContent = vendorDataBind.getVendorDataBind(buyer, content.questionOneContent, content.contentLocale);
  var answerTwoContent = vendorDataBind.getVendorDataBind(buyer, content.questionTwoContent, content.contentLocale);
  var answerThreeContent = vendorDataBind.getVendorDataBind(buyer, content.questionThreeContent, content.contentLocale);
  var questionFourTitle = vendorDataBind.getVendorDataBind(buyer, content.questionFourTitle, content.contentLocale);
  var questionFourContent = vendorDataBind.getVendorDataBind(buyer, content.questionFourContent, content.contentLocale);
  var questionOneTitle = vendorDataBind.getVendorDataBind(buyer, content.questionOneTitle, content.contentLocale);
  
  var isJumbo = buyer === 'Jumbo';
  var isEscorts = buyer === 'Escorts Limited';
  var jumboContent = props.content.jumboContent;

  var escortQuestionThree = content.questionThreePV;

  // Third party content node is used due to free Contentful account workaround

  var displayEscortsContent = isEscorts && content.questionThreePV;
  var displayJumboContent = isJumbo && jumboContent;

  if (displayJumboContent) {
    var jumboAnswerOneContent = vendorDataBind.getVendorDataBind(buyer, jumboContent.questionOneContent);
    var jumboAnswerTwoContent = vendorDataBind.getVendorDataBind(buyer, jumboContent.questionTwoContent);
    var jumboQuestionFourContent = vendorDataBind.getVendorDataBind(buyer, jumboContent.questionFourContent);
    var jumboQuestionThree = jumboContent.questionThreeContent;
  }

  const renderThirdFaqContent = () => {
    if (displayJumboContent) {
      return jumboQuestionThree;
    } else if (displayEscortsContent) {
      return escortQuestionThree;
    } else {
      return answerThreeContent;
    }
  }

return (
  <section id='faqs' className={`${global.c2foBkgd__gray} ${styles.c2foFaq__section}`}>
    <div className={`${global.c2foFlex} ${global.c2foContainer__inner} ${global.c2foFlex__wrap}`}>
      <h3>{content.faqHeader}</h3>
      <div className={styles.c2foFaq__container}>
        <Accordion isChinese={isSimpleChinese} isTradChinese={isTradChinese}>
          <div label={questionOneTitle}>
            <p>{displayJumboContent ? jumboAnswerOneContent : answerOneContent}</p>
          </div>
          <div label={content.questionTwoTitle}>
              <p>{displayJumboContent ? jumboAnswerTwoContent : answerTwoContent}</p>
            </div>
          <div label={content.questionThreeTitle}>
            {renderThirdFaqContent()}
          </div>
          <div label={questionFourTitle}>
            <p>{displayJumboContent ? jumboQuestionFourContent : questionFourContent}</p>
          </div>
        </Accordion>
      </div>
    </div>
  </section>
);
};

export default Faqs;