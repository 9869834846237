import React from 'react';
import oldGlobalStyles from '../../styles/global.module.css';
import newGlobalStyles from '../../styles/global-rebrand.module.css';
import oldAboutStyles from './about.module.css';
import newAboutStyles from './about-rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';
import vendorDataBind from '../../utils/vendorDataBind.js';

const About = (props) => {
  const rebranded = isRebranded();
  const styles = rebranded ? newAboutStyles : oldAboutStyles;
  const global = rebranded ? newGlobalStyles : oldGlobalStyles;
  const content = props.content.content;
  const buyer = props.content.buyer.name;

  var aboutContent = vendorDataBind.getVendorDataBind(buyer, content.aboutC2foContent, content.contentLocale);

  const renderAboutVideo = () => {
    if (content.aboutC2foVideo) {
      return (
        <video src={content.aboutC2foVideo} className={styles.c2fo_video_half} poster="https://static.c2fochina.cn/c2fo/assets/img/how_it_works_thumbnail_2023.jpg" controls>
          <source src={content.aboutC2foVideo} type="video/mp4"></source>
          <track kind="captions"></track>
        </video>)
    } else {
      return (
        <img alt="about-c2fo" src="/images/c2fo-afp-booth.jpg" />
      )
    }
  }


  return (
    <section id='about' className={styles.c2foAbout}>
      <div className={`${global.content} ${global.c2foContainer__inner}`}>
        <div className={styles.c2foAbout__container}>
          <div className={styles.c2foLetterContent__left}>
            <h2 className={global.c2foText__navy}>{content.aboutC2foHeader}</h2>
            <p>{aboutContent}</p>
          </div>
          <div className={styles.c2foLetterContent__right}>
            {renderAboutVideo()}
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
