import React, { Component } from "react";
import PropTypes from "prop-types";
import oldFaqStyles from './faqs.module.css';
import newFaqStyles from './faqs-rebrand.module.css';
import { isRebranded } from "../../utils/featureFlagHelpers";

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  render() {
    const styles = isRebranded() ? newFaqStyles : oldFaqStyles;
    let { onClick, props: { isOpen, label } } = this;

    if (isOpen === undefined) {
      isOpen = true;
    }

    let faqStyle;

    if (this.props.isTradChinese) {
      faqStyle = styles.c2foFaq__question_tradchinese;
    } else if (this.props.isChinese) {
      faqStyle = styles.c2foFaq__question_chinese;
    } else {
      faqStyle = styles.c2foFaq__question;
    }

    return (
      <div className={styles.c2foFaq__container}>
        <div onClick={onClick} className={faqStyle} style={{ cursor: "pointer" }} role="tab" tabIndex={0}>
          <h4 className={styles.c2foFaq__title}>{label}</h4>
          <div className={styles.c2foFaq__arrow}>
            {!isOpen && <span className={styles.caret__closed}></span>
            }
            {isOpen && <span className={styles.caret__open}></span>}
          </div>
        </div>
        {isOpen && (
          <div className={this.props.isChinese || this.props.isTradChinese ? styles.c2foFaq__answer_chinese : styles.c2foFaq__answer}>
            <p>{this.props.children}</p>
          </div>
        )}
      </div>
    );
  }
}

export default AccordionSection;