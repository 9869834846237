import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import vendorDataBind from '../../utils/vendorDataBind.js';
import oldGlobalStyles from '../../styles/global.module.css';
import newGlobalStyles from '../../styles/global-rebrand.module.css';
import oldBenefitsStyles from './benefits.module.css'
import newBenefitsStyles from './benefits-rebrand.module.css'
import { isRebranded } from '../../utils/featureFlagHelpers';

const Benefits = (props) => {
  const rebranded = isRebranded();
  const style = rebranded ? newBenefitsStyles : oldBenefitsStyles;
  const global = rebranded ? newGlobalStyles : oldGlobalStyles;
  const buyer = props.content.buyer.name;
  const content = props.content.content;
  var subHead = vendorDataBind.getVendorDataBind(buyer, content.benefitsSubhead, content.contentLocale);

  const renderIconCheck = () => {
    if (rebranded) {
      return (<i className={`${global.c2foIcon__check}`}></i>)
    }
    return (<i className={`${global.far} fa-check`}></i>)
  }

  // Select the correct device image based on if we're Rebranded or not
  const getDeviceImage = () => {
    if (isRebranded()) {
      return <img src="/images/re-brand-device.png" width="500" alt="c2fo" />
    } else {
      return <img src="/images/c2fo-macbook-phone-mockup.png" alt="c2fo" />
    }
  }

  return (
    <section id='benefits' className={style.c2foBenefits__section}>
      <div className={`${global.c2foFlex} ${style.c2foFlex} ${global.c2foContainer__inner} ${global.c2foFlex__wrap}`}>
        <div className={style.c2foBenefitsWorks__container}>
            { getDeviceImage() }
            <ScrollAnimation animateIn={style.fadeInRight} offset={100} duration={1} animateOnce={true}>
            <div className={style.c2foBenefitsIcon__container}>
                <h3 className={style.c2foBenefits__headline}>{ content.benefitsHeader}</h3>
                <p className={style.c2foBenefits__subtitle}>{subHead}</p>
                <div className={style.c2foBenefits__icon}>
                {renderIconCheck()}
                  <div className={style.c2foBenefits__content}>
                    <h4>{content.benefitsBulletTwoTitle}</h4>
                    <p>{content.benefitsBulletTwoContent}</p>
                  </div>
                </div>
                <div className={style.c2foBenefits__icon}>
                {renderIconCheck()}
                  <div className={style.c2foBenefits__content}>
                    <h4>{content.benefitsBulletThreeTitle}</h4>
                    <p>{content.benefitsThreeContent}</p>
                  </div>
                </div>
              </div>
            </ScrollAnimation>
        </div>
      </div>
    </section>
  );
};

export default Benefits;
