import React from 'react';
import ScrollAnimation from 'react-animate-on-scroll';
import vendorDataBind from '../../utils/vendorDataBind.js';
import oldWorksStyles from './works.module.css';
import newWorksStyles from './works-rebrand.module.css';
import oldGlobalStyles from '../../styles/global.module.css';
import newGlobalStyles from '../../styles/global-rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';


const Works = (props) => {
  const rebranded = isRebranded();
  const styles = rebranded ? newWorksStyles : oldWorksStyles;
  const global = rebranded ? newGlobalStyles : oldGlobalStyles;

  const buyer = props.content.buyer.name;
  const content = props.content.content;
  const strippedContent = content.worksBulletThreeContent.replace(/\.\./g, ' ');
  var bulletOneContent = vendorDataBind.getVendorDataBind(buyer, content.worksBulletOneContent, content.contentLocale);
  var bulletThreeTitle = vendorDataBind.getVendorDataBind(buyer, content.worksBulletThreeTitle, content.contentLocale);
  var bulletThreeContent = vendorDataBind.getVendorDataBind(buyer, strippedContent, content.contentLocale);
  var worksHeader = vendorDataBind.getVendorDataBind(buyer, content.programWorksHeader, content.contentLocale);


  return (
    <section id='works' className={`${global.c2foBkgd__gray} ${styles.c2foWorks__section}`}>
      <div className={`${global.c2foFlex} ${styles.c2foFlex} ${global.c2foContainer__inner} ${global.c2foFlex__wrap}`}>
        <h3>{worksHeader}</h3>
        <div className={styles.c2foRegistrationWorks__container}>
          <ScrollAnimation className={styles.c2foRegistration__icon} animateIn={styles.fadeInDown} offset={100} duration={0.5} animateOnce={true}>
            <div className={styles.c2foCircle}>
              <span className={styles.c2foWorks__number}>1</span>
            </div>
            <h4 className={global.c2foText__navy}>{content.worksBulletOneTitle}</h4>
            <div className={styles.c2foContent_wrap}>
              <p>{bulletOneContent}</p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className={styles.c2foRegistration__icon} animateIn={styles.fadeInDown} offset={100} duration={1} animateOnce={true}>
            <div className={styles.c2foCircle}>
              <span className={styles.c2foWorks__number}>2</span>
            </div>
            <h4 className={global.c2foText__navy}>{content.worksBulletTwoTitle}</h4>
            <div className={styles.c2foContent_wrap}>
              <p>{content.worksBulletTwoContent}</p>
            </div>
          </ScrollAnimation>
          <ScrollAnimation className={styles.c2foRegistration__icon} animateIn={styles.fadeInDown} offset={100} duration={1.5} animateOnce={true}>
            <div className={styles.c2foCircle}>
              <span className={styles.c2foWorks__number}>3</span>
            </div>
            <h4 className={global.c2foText__navy}>{bulletThreeTitle}</h4>
            <div className={styles.c2foContent_wrap}>
              <p>{bulletThreeContent}</p>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </section>
  );
};

export default Works;
