import React from 'react';
import vendorDataBind from '../../utils/vendorDataBind.js';
import oldGlobalStyles from '../../styles/global.module.css';
import styles from './intro_letter.module.css';
import newGlobalStyles from '../../styles/global-rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';
import { translateBuyerName } from '../../utils/ContentHelper'

const IntroLetter = (props) => {
  const rebranded = isRebranded();
  const global = rebranded ? newGlobalStyles : oldGlobalStyles;
  const buyer = props.content.buyer;
  const localeBuyerContent = props.content.localeContent;
  const content = props.content.content;
  const permalink = props.content.buyer.permalink;
  const isChinese = content.contentLocale.startsWith('zh');
  const isTurkish = content.contentLocale.startsWith('tr');

  let localeBuyer;
  if (localeBuyerContent.length > 1) {
    const filteredLocale = localeBuyerContent.filter(localeContent => localeContent.executiveContactName);
    if (filteredLocale.length < 1) {
      localeBuyer = localeBuyerContent[0];
    } else {
      localeBuyer = filteredLocale[0];
    }
  } else {
    localeBuyer = localeBuyerContent[0];
  }

  // buyer specific c2fochin.cn specific welcome letters
  const buyerCNWelcomeLetter = ["danone", "hpe", "cummins", "oriflame", "maersk", "walmart", "adeo", "tpk", "bestbuy", "boots"];
  const hasCNWelcomeLetter = buyerCNWelcomeLetter.includes(permalink);
  const letterUrl = `https://s3-us-west-2.amazonaws.com/c2fo-marketing-content/welcome-letters${hasCNWelcomeLetter ? "-china" : ""}/${permalink}-welcome-letter.pdf`

  // custom videos turkish only
  const customTurkishVideos = {
    anadoluefes: "https://player.vimeo.com/video/431527274?title=0&byline=0&portrait=0"
  }

  // china has static video content hosted on s3 instead of youtube due to support through the china firewall
  const customChineseVideos = {
    philips: [{ thumbnail: "/images/philips-video-poster.jpg", videoUrl: "https://static.c2fo.com/c2fo/assets/img/2019-10-Philips-孙灏.mp4" }],
    perfetti: [{ thumbnail: "https://static.c2fo.com/c2fo/assets/img/perfetti-video-placeholder.jpg", videoUrl: "https://static.c2fo.com/c2fo/assets/video/%E7%8E%8B%E4%BD%B3%E6%85%A7.mp4" }],
    zkh360: [
      { thumbnail: "https://static.c2fo.com/c2fo/assets/img/zkh-video-placeholder-1.jpg", videoUrl: "https://static.c2fo.com/c2fo/assets/video/李俊雨.mp4" },
      { thumbnail: "https://static.c2fo.com/c2fo/assets/img/zkh-video-placeholder-2.jpg", videoUrl: "https://static.c2fo.com/c2fo/assets/video/王亚龙.mp4" }
    ],
    zjlianhua: [
      { thumbnail: "https://static.c2fo.com/c2fo/assets/img/lianhua-treasury-thumbnail.png", videoUrl: "https://static.c2fo.com/c2fo/assets/video/lianhua-treasury.mp4" },
      { thumbnail: "https://static.c2fo.com/c2fo/assets/img/lianhua-procurement-thumbnail.png", videoUrl: "https://static.c2fo.com/c2fo/assets/video/lianhua-procurement.mp4" }
    ],
    casestudy: [{ thumbnail: "https://static.c2fo.com/c2fo/assets/img/trayton-thumbnail.jpg", videoUrl: "https://static.c2fo.com/c2fo/assets/video/trayton_testimonial.mp4" }],
    danone: [{ thumbnail: "https://static.c2fo.com/c2fo/assets/img/Danone_thumb.jpg", videoUrl: "https://static.c2fochina.cn/c2fo/assets/video/DanoneEarlyPaymentProgramIntro.mp4" }],
    whitbread: [{ thumbnail: "https://static.c2fo.com/c2fo/assets/img/whitbread-thumbnail.jpeg", videoUrl: "https://static.c2fo.com/c2fo/assets/video/whitbread_c2fo_earlypayment.mp4" }],
    adeo: [{ thumbnail: "https://static.c2fo.com/c2fo/assets/img/adeo-thumbnail.png", videoUrl: "https://static.c2fo.com/c2fo/assets/video/CH_Sub_Adeo_Exec_Sponsor_Video.mp4" }],
  }

  const customChineseWelcomeLetterVideo = {
    walmart: { thumbnail: "https://static.c2fo.com/c2fo/assets/img/Walmart_China_video_thumbnail.jpg", videoUrl: "https://static.c2fo.com/c2fo/assets/video/Walmart_China_Early_Payment_Introduction.mp4" },
    tpk: { thumbnail: "https://static.c2fo.com/c2fo/assets/img/tpk_video_thumbnail.png", videoUrl: "https://static.c2fo.com/c2fo/assets/video/TPKVideoInterviewfinal.mp4" },
  }

  const hideCustomVideoCN = ["cummins"].includes(permalink)
  const showCustomVideoCN = isChinese && !hideCustomVideoCN;
  const customVideoCNKey = !(permalink in customChineseVideos) ? 'casestudy' : permalink;
  const videoDataCN = customChineseVideos[customVideoCNKey];

  const translatedBuyerName = translateBuyerName(content.contentLocale, buyer.name);

  var uberBuyerName;

  if (permalink === 'uber') {
    uberBuyerName = buyer.name.split(' ').length >= 3 ? buyer.name.split(' ')[2] : '';
    var letterHeader = vendorDataBind.getVendorDataBind(uberBuyerName, content.introductionLetterHeader, content.contentLocale);
  }
  else {
    // replace all {buyername} placeholders with translated buyername
    var letterHeader = vendorDataBind.getVendorDataBind(buyer.name, content.introductionLetterHeader, content.contentLocale);
  }
  var letterBtn = vendorDataBind.getVendorDataBind(buyer.name, content.introductionLetterButton, content.contentLocale);

  // if executiveContactTitle not authored render empty string
  const contactTitle = localeBuyer.executiveContactTitle || '';
  // Custom Name and Title for tpk in traditional chinese
  const getTpkExecTitle = () => {
    let tpkExecNameTitle = `${localeBuyer.executiveContactName}, ${contactTitle}`;
    if(content.contentLocale === 'zh-CN') {
      tpkExecNameTitle = '李少华, 财务资金处处长';
    }
    return `${letterHeader} ${tpkExecNameTitle} ${translatedBuyerName}`;
  }

  const getDanoneExecTitle = () => {
    let danoneExecNameTitleBuyerName = `Siyi FANG, Zone Cycles & Procurement VP ${translatedBuyerName}`;
    if(content.contentLocale === 'zh-CN') {
      danoneExecNameTitleBuyerName = '方思怡, 采购副总裁 达能中国、北亚及大洋洲区域';
    }
    return `${letterHeader} ${danoneExecNameTitleBuyerName}`;
  }

  // buyer custom formats for intro headline
  const headlineVariants = {
    inorbit: `${letterHeader} ${translatedBuyerName}`,
    jmc: `${letterHeader} ${translatedBuyerName}`,
    naturelle: `${letterHeader} ${localeBuyer.executiveContactName}, ${contactTitle}`,
    apple: `${letterHeader} ${localeBuyer.executiveContactName}, ${contactTitle}`,
    anadoluefes: `${letterHeader} ${localeBuyer.executiveContactName} ${translatedBuyerName} — ${contactTitle}`,
    sify: `${letterHeader} ${translatedBuyerName}`,
    // edge case specific to Danone China launch https://honeycomb.jira.com/browse/EASEASM-33
    danone: getDanoneExecTitle(),
    // edge case specific to Cummins China launch https://honeycomb.jira.com/browse/EASEASM-35
    cummins: `${letterHeader} Eric Zhang, Director, China ABO Purchasing ${translatedBuyerName}`,
    betterlife: content.contentLocale === 'zh-CN' ? `${letterHeader}` : `${letterHeader} ${translatedBuyerName}`,
    uber: `${letterHeader} ${localeBuyer.executiveContactName}, ${contactTitle} ${uberBuyerName}`,
    walmart: `${letterHeader} ${isChinese ?
      '吴瑞珊, 资金部总监 沃尔玛中国' // Custom Name, Title and Buyer name for walmart China
      :
      `${localeBuyer.executiveContactName}, ${contactTitle} ${translatedBuyerName}`
      }`,
    tpk: getTpkExecTitle(),
    DEFAULT: `${letterHeader} ${localeBuyer.executiveContactName}, ${contactTitle} ${translatedBuyerName}`
  }

  const displayIntroSection = localeBuyer.executiveContactName || permalink in headlineVariants;

  const renderIntroMedia = () => {
    let videoSrc = '';
    // set video src for turkish and non regional custom Videos
    if (isTurkish && permalink in customTurkishVideos) {
      videoSrc = customTurkishVideos[permalink]
    }

    if (permalink in customChineseWelcomeLetterVideo) {
      return (
        <video width="640" height="360" poster={customChineseWelcomeLetterVideo[permalink].thumbnail} controls controlsList="nodownload" onContextMenu={(e) => e.preventDefault()}>
          <source src={customChineseWelcomeLetterVideo[permalink].videoUrl} type="video/mp4"></source>
        </video>
      )
    }
    // default to Welcome Letter without custom video set
    return (videoSrc ?
      <iframe title={props.content.buyer.name} src={videoSrc} width="640" height="360" frameBorder="0" webkitallowfullscreen mozallowfullscreen allowFullScreen></iframe>
      :
      <embed src={`${letterUrl}#toolbar=0&navpanes=0&scrollbar=0`} width="600" height="500" />
    )
  }

  const renderHeadline = () => {
    let key = permalink in headlineVariants ? permalink : 'DEFAULT';
    return headlineVariants[key];
  }

  const renderChinaBuyerVideos = () => {
    // render for 2 column video
    if (videoDataCN.length > 1) {
      return (
        <div>
          <video className={styles.c2fo_video_half} poster={videoDataCN[0].thumbnail} controls controlsList="nodownload" onContextMenu={(e) => e.preventDefault()}>
            <source src={videoDataCN[0].videoUrl} type="video/mp4"></source>
          </video>
          <video className={styles.c2fo_video_half} poster={videoDataCN[1].thumbnail} controls controlsList="nodownload" onContextMenu={(e) => e.preventDefault()}>
            <source src={videoDataCN[1].videoUrl} type="video/mp4"></source>
          </video>
        </div>
      )
    }
    // dafault to single Column Video
    return (
      <video className={styles.c2fo_video_half} poster={videoDataCN[0].thumbnail} controls controlsList="nodownload" onContextMenu={(e) => e.preventDefault()}>
        <source src={videoDataCN[0].videoUrl} type="video/mp4"></source>
      </video>
    )
  }

  return (
    <div>
      {displayIntroSection &&
        <section id='intro' className={`${global.c2foSection__container} ${global.c2foBkgd__gray}`}>
          <div className={`${global.content} ${global.c2foContainer__inner}`}>
            <div className={global.c2foFlex}>
              <div className={styles.c2foLetter__content__left}>
                <h3 className={`${global.c2foText__navy} ${global.c2foContainer__inner__headline} ${styles.c2foLetter__headline}`}>{renderHeadline()}</h3>
                <div className={`${global.c2foCta__container} ${styles.c2foCta_letter}`}>
                  <a href={letterUrl} className={global.c2foCta} target="_blank" rel="noopener noreferrer nofollow" >{letterBtn}</a>
                </div>
              </div>
              <div className={`${styles.c2foLetter__content__right} ${styles.c2foLetter__ie11}`}>
                {renderIntroMedia()}
              </div>
            </div>``
          </div>
        </section>
      }
      {showCustomVideoCN &&
        <section id="casestudy" className={`${global.c2foSection__container} ${global.c2foBkgd__gray}`}>
          {(customVideoCNKey === 'casestudy') && <h3>提前付款项目供应商访谈</h3>}
          <div className={`${global.content} ${styles.c2foBuyer__videos}`}>
            {renderChinaBuyerVideos()}
          </div>
          <div>
            {/* This is a hardcoded chinese disclaimer due to no space in CMS. */}
            <p>本网页内的视频/资料未经许可，任何人不得非法复制、转载发布以及用于商业用途。</p>
          </div>
        </section>}
    </div>
  );
};

export default IntroLetter;
