import React from 'react';
import oldGlobalStyles from '../../styles/global.module.css';
import newGlobalStyles from '../../styles/global-rebrand.module.css';
import oldReviewStyles from './reviews.module.css';
import newReviewStyles from './reviews-rebrand.module.css';
import { isRebranded } from '../../utils/featureFlagHelpers';

const Reviews = (props) => {
    const rebranded = isRebranded();
    const styles = rebranded ? newReviewStyles : oldReviewStyles;
    const global = rebranded ? newGlobalStyles : oldGlobalStyles;
    const starIconSrc = rebranded ? "/images/rebrand/star.png" : "/images/star.svg";

    const content = props.content.content;

    var videoContent1 = content.successStory1Url;
    var videoContent2 = content.successStory2Url;

    const showVideoOne = videoContent1;
    const showVideoTwo = videoContent2;

    const review = props.content.reviews;
    const isFrench = content.contentLocale === 'French';

    return (
        <section id='reviews' className={styles.c2foSuccess__videos__container}>
            <h3 className={global.c2foContainer__headline}>{content.earlyPaymentHeader}</h3>
            { showVideoOne || showVideoTwo ?
            <div className={`${global.content} ${styles.c2foSuccess__videos__content}`}>
                <div className={styles.c2foSuccess__videos}>
                    { showVideoOne ?
                    <iframe width="560" height="315" title="success video" src={videoContent1} allowFullScreen></iframe> : '' }
                    { showVideoTwo ?
                    <iframe width="560" height="315" title="success video" src={videoContent2} allowFullScreen></iframe> : '' }
                </div>
            </div>
            :''}
            {!isFrench ?
            <div className={`${global.content} ${styles.c2foReviews}`}>
                <div className={`${global.content} ${styles.c2foReview__container}`}>
                    <div className={styles.c2foReview__stars}>
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                    </div>
                    <div className={styles.c2foReview__content}>
                        <h4>{review.firstReviewTitle}</h4>
                        <p>{review.firstReviewContent}</p>
                        <p className={styles.c2foReview__name}>{review.firstReviewName}</p>
                    </div>
                </div>
                <div className={`${global.content} ${styles.c2foReview__container}`}>
                    <div className={styles.c2foReview__stars}>
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />                    </div>
                    <div className={styles.c2foReview__content}>
                        <h4>{review.secondReviewTitle}</h4>
                        <p>{review.secondReviewContent}</p>
                        <p className={styles.c2foReview__name}>{review.secondReviewName}</p>
                    </div>
                </div>
                <div className={`${global.content} ${styles.c2foReview__container}`}>
                    <div className={styles.c2foReview__stars}>
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />
                        <img className={styles.c2foStar__ico} alt="review-star" src={starIconSrc} />                    </div>
                    <div className={styles.c2foReview__content}>
                        <h4>{review.thirdReviewTitle}</h4>
                        <p>{review.thirdReviewContent}</p>
                        <p className={styles.c2foReview__name}>{review.thirdReviewName}</p>
                    </div>
                </div>
                <div className={styles.c2foTrustpilot__image}>
                    {
                        isRebranded() ?
                            <p className={styles.c2foTrustpilot__review}>Excellent, 4.7 out of 5</p>
                                :
                            <p className={styles.c2foTrustpilot__review}>Excellent, 8.4 out of 10.</p>
                    }
                    <img src="/images/Trustpilot_brandmark.png" alt="trust pilot"/>
                </div>
            </div>
            : '' }
        </section>
    );
};

export default Reviews;
