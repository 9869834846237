export const FIELDS_INDEX = [
    {
        locale: 'en-ks', 
        company: 'Your company name*',
        vendorid: 'Vendor ID',
        firstname: 'First Name*',
        lastname: 'Last Name*',
        password: 'Password*',
        verifypassword: 'Verify Password*',
        email: 'E-mail*',
        phone: 'Phone*',
        customer: 'Customer',
        address: 'Address*',
        city: 'City*',
        state: 'State*',
        zip: 'Postal Code*',
        country: 'Country*',
        fiscalyear: 'Fiscal Year End',
        annualrevenue: 'Annual Revenue',
        role: 'Your role*',
        cfo: 'CFO',
        controller: 'Controller',
        creditmanager: 'Credit Manager',
        financedirector: 'Finance Director',
        treasurer: 'Treasurer',
        ceo: 'CEO',
        president: 'President',
        owner: 'Owner/Founder',
        accounting: 'Accounting',
        bookkeeper: 'Bookkeeper',
        accounts: 'Accounts Receivable ',
        officemanager: 'Office Manager',
        other: 'Other',
    },
      {
        locale: 'de-de', 
        company: 'Unternehmen*',
        vendorid: 'Lieferanten ID',
        firstname: 'Vorname*',
        lastname: 'Nachname*',
        password: 'Password*',
        verifypassword: 'Verify Password*',
        email: 'E-mail*',
        phone: 'Telefon*',
        customer: 'Customer',
        address: 'Address*',
        city: 'City*',
        state: 'State*',
        zip: 'Postal Code*',
        country: 'Country*',
        fiscalyear: 'Fiscal Year End',
        annualrevenue: 'Annual Revenue',
        role: 'Position (bitte wählen)*',
        cfo: 'Finanzvorstand',
        controller: 'Finanzkontrolleur',
        creditmanager: 'Kreditmanager',
        financedirector: 'Finanzchef',
        treasurer: 'Schatzmeister',
        ceo: 'Vorsitzender',
        president: 'Präsident',
        owner: 'Inhaber / Gründer',
        accounting: 'Buchhaltung',
        bookkeeper: 'Buchhalter',
        accounts: 'Debitorenkonto',
        officemanager: 'Büroleiter',
        other: 'Sonstige',
    },
    {
        locale: 'fr-fr', 
        company: 'Entreprise*',
        vendorid: 'Fournisseur ID',
        firstname: 'Prénom*',
        lastname: 'Nom*',
        password: 'Password*',
        verifypassword: 'Verify Password*',
        email: 'E-mail*',
        phone: 'Téléphone*',
        customer: 'Customer',
        address: 'Address*',
        city: 'City*',
        state: 'State*',
        zip: 'Postal Code*',
        country: 'Country*',
        fiscalyear: 'Fiscal Year End',
        annualrevenue: 'Annual Revenue',
        role: 'Fonction*',
        cfo: 'CFO',
        controller: 'Manette',
        creditmanager: 'Gestionnaire de crédit',
        financedirector: 'Finance',
        treasurer: 'Trésorerie',
        ceo: 'CEO',
        president: 'Président',
        owner: 'Propriétaire / Fondateur',
        accounting: 'Comptabilité',
        bookkeeper: '',
        accounts: 'Comptes débiteurs',
        officemanager: 'Responsable administratif',
        other: 'Autre',
    }
];

// temp solution for including translations of buyername
// key uses { locale: {buyer.name: "transation"}}
export const BUYER_NAME_TRANSLATIONS = {
    "zh-CN": {
      "Acer": "宏碁",
      "Amazon": "亚马逊",
      "Betterlife": "步步高",
      "CRV": "华润万家",
      "CSPC": "中海壳牌",
      "Cummins": "康明斯",
      "Danone": "达能",
      "Eaton": "伊顿",
      "Flex": "伟创力",
      "Ford": "福特",
      "HP": "惠普",
      "Intel": "英特尔",
      "Logitech": "罗技",
      "Macy's": "梅西百货",
      "Mondelez": "亿滋",
      "Pfizer": "辉瑞",
      "Philips": "飞利浦",
      "Perfetti Van Melle": "不凡帝范梅勒",
      "Siemens": "西门子",
      "Walmart": "沃尔玛",
      "YTO": "圆通",
      "ZKH": "震坤行",
      "JMC": "江铃汽车",
    },
    "zh-TW": {
      "JMC": "江鈴汽車",
    }
  }