import { translateBuyerName } from '../utils/ContentHelper'

const vendorDataBind = {
    getVendorDataBind: (buyerName, post, locale="en-US") => {
    const translatedBuyerName = translateBuyerName(locale, buyerName)
    var contentTransform = post;
    if (contentTransform) {
      var vendor = contentTransform.split(/(\bbuyer+\b)/gi);
      for (var i = 1; i < vendor.length; i += 2) {
          vendor[i] = translatedBuyerName;
      }
        return vendor.join("");
      }
    }
  }
  
  export default vendorDataBind;