// This form is used so netlify to pickup during build time as netlify forms.
// this is due to using a javascript rendered form

import React from "react"

const RegFormSSG = props => (
  <form
    name="microsite-registration"
    align="left"
    style={{ display: "none" }}
    data-netlify="true"
  >
    <input type="hidden" name="form-name" value="microsite-registration" />
    <input name="firstname" type="text" />
    <input name="lastname" type="text" />

    <input name="companyName" type="text" />
    <select name="role" title="Title/Role">
      <option value=""></option>
      <option value="CFO"></option>
      <option value="Controller"></option>
      <option value="Credit Manager"></option>
      <option value="Finance Director"></option>
      <option value="Treasurer"></option>
      <option value="CEO"></option>
      <option value="President"></option>
      <option value="Owner / Founder"></option>
      <option value="Accounting"></option>
      <option value="Bookkeeper"></option>
      <option value="Accounts Receivable"></option>
      <option value="Office Manager"></option>
      <option value="Other"></option>
    </select>

    <input name="workEmail" type="email" />
    <input name="phone" type="text" />
    <select name="hearAbout">
      <option value=""></option>
      <option value="Received a Letter"></option>
      <option value="Email"></option>
      <option value="Social Media"></option>
      <option value="Online Search"></option>
      <option value="Family/Friend/Acquaintance"></option>
      <option value="At an event"></option>
      <option value="Other"></option>
    </select>
    <input type="hidden" name="Buyer_Name__c" />
    <input type="hidden" name="Utm_content__c" />
    <input type="hidden" name="Utm_medium__c" />
    <input type="hidden" name="Utm_source__c" />
    <input type="hidden" name="Utm_term__c" />
    <input type="hidden" name="Utm_campaign__c" />
    <input type="hidden" name="Utm_audience__c" />
    <input type="hidden" name="full_buyer_name" />
    <input type="hidden" name="headerImageUrl" />
    <input type="hidden" name="Buyer_Division_UUID__c" />
    <input type="hidden" name="Organization_uuid" />
    <input type="hidden" name="locale" />
    <button></button>
  </form>
)

export default RegFormSSG
