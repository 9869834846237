import { BUYER_NAME_TRANSLATIONS } from '../../content/translations';

//returns content in a specific locale if defined, if not, returns the default content
export function renderContentLocale(content, defaultContent) {
    if (content) {
        return content;
    } else {
        return defaultContent;
    }
}

// temp solution for including translations of buyername
// key uses { locale: {permalink: "transation"}}
export const translateBuyerName = (locale, buyername) => {
    if (locale in BUYER_NAME_TRANSLATIONS) {
      if (buyername in BUYER_NAME_TRANSLATIONS[locale]) {
        return BUYER_NAME_TRANSLATIONS[locale][buyername]
      }
    }
    return buyername
  }